import type * as stripeJs from '@stripe/stripe-js'
import { colors } from '@unocss/preset-mini/colors'
import { isClient } from '@vueuse/core'

export const isDarkMode = () => isClient && window.document.documentElement.classList.contains('dark-mode')

export default defineAppConfig({
  stripe: {
    elements: {
      options: {
        fonts: [
          {
            family: 'Averta',
            weight: '400',
            display: 'swap',
            src: 'url(https://www.scontent.uzo.io/fonts/Averta-Regular.woff)',
          },
          {
            family: 'Averta',
            weight: '500',
            display: 'swap',
            src: 'url(https://www.scontent.uzo.io/fonts/Averta-Semibold.woff)',
          },
          {
            family: 'Averta',
            weight: '700',
            display: 'swap',
            src: 'url(https://www.scontent.uzo.io/fonts/Averta-Bold.woff)',
          },
        ],
      },
    },

    card: {
      // hidePostalCode: true,
      classes: {
        base: '',
        complete: '',
        empty: '',
        focus: '',
        invalid: '',
        webkitAutofill: '',
      },
      style: {
        base: {
          'backgroundColor': 'transparent',
          'color': isDarkMode() ? colors.slate[700] : colors.slate[900],
          'fontFamily': 'inherit',
          'fontSize': '16px',
          // 'fontSize': '32px',
          'fontSmoothing': 'antialiased',
          'lineHeight': '20px',
          //
          'iconColor': isDarkMode() ? colors.slate[400] : colors.slate[500],
          //
          ':hover': {},
          ':focus': {
            color: isDarkMode() ? colors.slate[700] : colors.slate[900],
          },
          '::placeholder': {
            color: isDarkMode() ? colors.slate[400] : colors.slate[500],
          },
          '::selection': {},
          ':-webkit-autofill': {},
          ':disabled': {
            color: colors.slate[500],
          },
        },
        complete: {},
        empty: {},
        invalid: {
          'iconColor': isDarkMode() ? colors.red[400] : colors.rose[400],
          'color': isDarkMode() ? colors.rose[200] : colors.red[900],
          '::placeholder': {
            color: isDarkMode() ? colors.red[400] : colors.rose[400],
          },
        },
      },
    } satisfies stripeJs.StripeCardElementOptions,
  },
})
