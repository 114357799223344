<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router'
import { NuxtLinkLocale } from '#components'

const props = defineProps<{
  to?: RouteLocationRaw
  icon?: string
}>()

const isRelative = computed(() => ((props.to ?? '') as string).startsWith('/'))
</script>

<template>
  <Component
    :is="isRelative ? NuxtLinkLocale : 'button'"
    :to="to"
    v-bind="$attrs"
    class="n-button n-button-base active:n-button-active focus-visible:n-focus-base n-transition hover:n-button-hover n-disabled:n-disabled"
  >
    <slot name="icon">
      <NIcon v-if="icon" :icon="icon" class="n-button-icon" />
    </slot>
    <slot />
  </Component>
</template>
