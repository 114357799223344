import type * as stripeJs from '@stripe/stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import type { ModuleOptions } from '../module'
import { useRuntimeConfig } from '#imports'

let stripePromise: Promise<stripeJs.Stripe | null>

function getStripePromise(options: ModuleOptions) {
  const { publishableKey, advancedFraudSignals, options: stripeOptions } = options

  useHead({
    script: [{
      src: `https://js.stripe.com/v3${advancedFraudSignals ? '?advancedFraudSignals=true' : ''}`,
      async: true,
    }],
  })

  if (typeof window === 'undefined')
    return Promise.resolve(null)

  if (!stripePromise)
    stripePromise = loadStripe(publishableKey, stripeOptions)

  return stripePromise
}

export default defineNuxtPlugin(() => {
  const stripeConfig = useRuntimeConfig().public.stripe
  return {
    provide: {
      stripePromise: getStripePromise(stripeConfig),
    },
  }
})
