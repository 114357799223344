import { datadogRum } from '@datadog/browser-rum'
import { defineNuxtPlugin, useRuntimeConfig } from '#imports'

export default defineNuxtPlugin((_nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()
  const initRumConfig = runtimeConfig.public.datadog.rum

  if (!process.dev) {
    datadogRum.init(initRumConfig)

    datadogRum.startSessionReplayRecording()

    const ctx = datadogRum.getInternalContext()
    const applicationId = ctx?.application_id ?? 'NULL'
    const sessionId = ctx?.session_id ?? 'NULL'

    // eslint-disable-next-line no-console
    console.debug(`Datadog RUM initialized with applicationId: ${applicationId} and sessionId: ${sessionId}`)
  }

  return {
    provide: {
      datadogRum,
    },
  }
})
