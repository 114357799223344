import { configure, defineRule } from 'vee-validate'
import * as rules from '@vee-validate/rules'
import { localize, setLocale } from '@vee-validate/i18n'
import cs from '@vee-validate/i18n/dist/locale/cs.json'
import en from '@vee-validate/i18n/dist/locale/en.json'

localize({ en, cs })

export default defineNuxtPlugin((nuxtApp) => {
  Object.keys(rules)
    .filter(rule => rule !== 'default')
    .forEach(rule => defineRule(rule, (rules as Record<string, any>)[rule]))

  setLocale(unref((nuxtApp.$i18n as any).locale))

  configure({
    generateMessage: localize({ en, cs }),
  })
})
