import type { FetchOptions } from 'ofetch'
import type { LocaleObject } from '@nuxtjs/i18n'
import type { Ref } from 'vue'
import { randomUUID } from 'uncrypto'

export default defineNuxtPlugin((nuxtApp) => {
  const state = useGraphqlState()
  const headers = useRequestHeaders(['cookie', 'x-request-id'])

  state.fetchOptions = {
    onRequest: (context) => {
      const locale = ((nuxtApp.$i18n as any).locale as Ref<string>).value
      const localeObject = ((nuxtApp.$i18n as any).locales as Ref<LocaleObject[]>).value.find(l => l.code === locale)

      context.options.params ??= {}
      context.options.params.languageCode = locale
      context.options.params.currencyCode = localeObject?.currency ?? 'USD'

      context.options.headers = {
        ...headers,
        'Content-Language': locale,
        'Content-Currency': localeObject?.currency ?? 'USD',
        'X-Request-Id': randomUUID(),
      }
    },
  } satisfies FetchOptions

  // nuxtApp.hooks.hook('i18n:beforeLocaleSwitch', (localSwitch) => {
  //   graphQLState.languageCode = localSwitch.newLocale
  // })

  nuxtApp.hooks.hook('i18n:localeSwitched', async (_localSwitch) => {
    // TODO: proper state management
    // useStore().value = {}

    // reload page for sake of simplicity
    window.location.reload()
  })
})
