
import * as ipxRuntime$sFhzH7vWpc from '/usr/local/app/node_modules/.pnpm/@nuxt+image@1.2.0_rollup@3.29.4/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "localhost:3000",
    "diamido.com",
    "staging.diamido.com",
    "storefront-staging.diamido.com",
    "storefront-production.diamido.com",
    "vendure.uzo.io",
    "staging.vendure.uzo.io",
    "vendure.staging.uzo.io"
  ],
  "alias": {},
  "densities": [
    1,
    2,
    1,
    2
  ],
  "format": [
    "avif",
    "webp",
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$sFhzH7vWpc, defaults: {"maxAge":31536000,"modifiers":{"quality":75,"sharpen":true,"normalize":true}} }
}
        